import { createMuiTheme } from '@material-ui/core/styles';
import { brown } from '@material-ui/core/colors';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#44874B',
    },
    secondary: {
      main: '#6C91C2',
    },
    error: {
      main: '#93323A',
    },
    background: {
      default: brown[50],
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: [
      'Raleway',
      'sans-serif',
    ],
  },
});

export default theme;
