import React, { useState, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import Close from '@material-ui/icons/Close';
import { Modal, GridList, Box, GridListTile, IconButton, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tile: {
    display: 'block',
    cursor: 'pointer',
  },
  modalImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    margin: '0 auto',
    outline: '0',
  },
  modalImage: {
    display: 'flex',
    maxHeight: '80vh',
    maxWidth: '95vw',
    boxShadow: '0 0 25px rgba(0, 0, 0, .8)'
  },
  imageNav: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    color: 'white !important',
    fontSize: '16',
  }
}));

export default function PictureGrid({ imagePaths }) {
  const classes = useStyles();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [imageOpen, setImageOpen] = useState(null)

  const onClick = useCallback((image) => () => {
    setImageOpen(image)
  }, [setImageOpen])

  const onClose = useCallback(() => setImageOpen(null), [setImageOpen])

  const previous = useCallback(() => {
    let next = imageOpen - 1
    if (next < 0) {
      next = imagePaths.length - 1
    }
    console.log(next)
    setImageOpen(next)
  }, [setImageOpen, imageOpen])

  const next = useCallback(() => {
    let next = imageOpen + 1
    if (next === imagePaths.length) {
      next = 0
    }
    console.log(next)
    setImageOpen(next)
  }, [setImageOpen, imageOpen])

  const onKeyDown = useCallback((event) => {
    if (event.keyCode === 37) {
      previous()
    } else if (event.keyCode === 39) {
      next()
    }
  }, [previous, next])

  return (
    <Box my={4} width='100%'>
      <GridList cellHeight={240} cols={isMobile ? 2 : 3}>
        {imagePaths.map((image, index) => (
          <GridListTile key={image} onKeyDown={onKeyDown}>
            <img
              src={`/images/${image}`}
              onClick={onClick(index)}
              className={classes.tile}
              alt=''
            />
            <Modal
              open={imageOpen === index}
              onClose={onClose}
              BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,.7)' } }}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box className={classes.modalImageContainer}>
                <img src={`/images/${image}`} className={classes.modalImage} alt='' />
                <Box className={classes.imageNav}>
                  <IconButton color='inherit' onClick={previous}><NavigateBefore style={{ fontSize: 40 }} /></IconButton>
                  <IconButton color='inherit' onClick={onClose}><Close style={{ fontSize: 40 }} /></IconButton>
                  <IconButton color='inherit' onClick={next}><NavigateNext style={{ fontSize: 40 }} /> </IconButton>
                </Box>
              </Box>
            </Modal>
          </GridListTile>
        ))}
      </GridList>
    </Box>
  );
}
