import React, { Fragment, useState, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ComposableMap, Geography, Geographies, Marker, Line, ZoomableGroup } from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';
import { List, ListItem, ListItemText } from '@material-ui/core';
import PictureGrid from './PictureGrid';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const markers = [
  {
    xOffset: 0,
    yOffset: -30,
    name: "Columbus",
    coordinates: [-83.1309115, 39.9828671]
  },
  {
    stateName: "South Dakota",
    markerOffset: -45,
    name: "Rapid City, SD",
    date: "July 19th",
    coordinates: [-103.3226626, 44.0696281]
  },
  {
    stateName: "South Dakota",
    xOffset: 0,
    yOffset: 20,
    id: 'badlands',
    numPics: 3,
    name: "Badlands Natl. Park",
    date: "July 19th",
    coordinates: [-102.332787, 43.907810]
  },
  // {
  //   stateName: "South Dakota",
  //   xOffset: 0,
  //   yOffset: -30,
  //   name: "Wind Cave Natl. Park",
  //   date: "July 20th",
  //   coordinates: [-103.441269, 43.586269]
  // },
  {
    stateName: "Montana",
    xOffset: 0,
    yOffset: -30,
    id: 'glacier',
    numPics: 3,
    name: "Glacier Natl. Park",
    date: "July 21st - 23rd",
    coordinates: [-113.787025, 48.759613]
  },
  {
    stateName: "Washington",
    xOffset: 40,
    yOffset: -40,
    id: 'northcascades',
    numPics: 4,
    name: "North Cascades Natl. Park",
    date: "July 24th - 26th",
    coordinates: [-121.7061146, 48.7168353]
  },
  {
    stateName: "Washington",
    xOffset: 47,
    yOffset: -8,
    name: "Seattle, WA",
    date: "July 27th- 28th",
    coordinates: [-122.3492774, 47.4205063]
  },
  {
    stateName: "Washington",
    xOffset: 10,
    yOffset: -34,
    id: 'olympic',
    numPics: 5,
    name: "Oympic Natl. Park",
    date: "July 29th",
    coordinates: [-124.2005992, 47.785354]
  },
  {
    stateName: "Washington",
    xOffset: 10,
    yOffset: 10,
    id: 'rainier',
    numPics: 3,
    name: "Mt. Rainier Natl Park",
    date: "July 30th",
    coordinates: [-120.8951016, 46.6602347]
  },
  {
    stateName: "Oregon",
    xOffset: 20,
    yOffset: -30,
    id: 'craterlake',
    numPics: 3,
    name: "Crater Lake Natl. Park",
    date: "July 31st",
    coordinates: [-122.4157377, 42.936009]
  },
  {
    stateName: "California",
    xOffset: 80,
    yOffset: -8,
    id: 'redwoods',
    numPics: 2,
    name: "Redwoods Natl. Park",
    date: "August 1st",
    coordinates: [-124.003075, 41.4358682]
  },
  {
    stateName: "California",
    xOffset: 98,
    yOffset: -8,
    id: 'lassen',
    numPics: 2,
    name: "Lassen Volcanic Natl. Park",
    date: "August 2nd",
    coordinates: [-121.5502994, 40.497139]
  },
  {
    stateName: "Nevada",
    xOffset: 0,
    yOffset: 10,
    name: "Reno, NV",
    date: "August 2nd - 3rd",
    coordinates: [-119.9609246, 39.5439891]
  },
  {
    stateName: "Nevada",
    xOffset: 0,
    yOffset: 12,
    id: 'greatbasin',
    numPics: 2,
    name: "Great Basin Natl. Park",
    date: "August 3rd",
    coordinates: [-114.389047, 38.9388506]
  },
  {
    stateName: "Utah",
    xOffset: 0,
    yOffset: 10,
    id: 'capitolreef',
    numPics: 6,
    name: "Capitol Reef Natl. Park",
    date: "August 4th",
    coordinates: [-111.8290461, 38.2634343]
  },
  {
    stateName: "Utah",
    xOffset: 0,
    yOffset: 10,
    name: "Moab, UT",
    date: "August 5th - 6th",
    coordinates: [-109.5843564, 38.5744598]
  },
  {
    stateName: "Utah",
    xOffset: 0,
    yOffset: 10,
    id: 'arches',
    numPics: 2,
    name: "Arches Natl. Park",
    date: "August 5th",
    coordinates: [-109.5732878, 38.715403]
  },
  {
    stateName: "Utah",
    xOffset: 0,
    yOffset: 10,
    id: 'canyonlands',
    numPics: 1,
    name: "Canyonlands Natl. Park",
    date: "August 6th",
    coordinates: [-110.4253091, 38.2267]
  },
  {
    stateName: "Colorado",
    xOffset: 0,
    yOffset: 10,
    id: 'mesaverde',
    numPics: 3,
    name: "Mesa Verde Natl. Park",
    date: "August 7th",
    coordinates: [-108.5898716, 37.2381218]
  },
  {
    stateName: "Colorado",
    xOffset: 0,
    yOffset: 10,
    id: 'greatsanddunes',
    numPics: 2,
    name: "Great Sand Dunes Natl. Park",
    date: "August 8th",
    coordinates: [-105.7317561, 37.7537895]
  },
  {
    stateName: "Colorado",
    xOffset: 0,
    yOffset: 10,
    name: "Boulder, CO",
    date: "August 9th - 10th",
    coordinates: [-105.3100172, 40.0294203]
  },
  // {
  //   stateName: "Colorado",
  //   xOffset: 0,
  //   yOffset: 10,
  //   name: "Rocky Mountain Natl. Park",
  //   date: "August 10th",
  //   coordinates: [-105.9510673, 40.3414785]
  // },
  {
    stateName: "Missouri",
    xOffset: 0,
    yOffset: 10,
    name: "St. Louis, MO",
    date: "August 11th",
    coordinates: [-90.3760667, 38.6442947]
  },
  {
    hideMarker: true,
    xOffset: 0,
    yOffset: -30,
    name: "Columbus",
    coordinates: [-83.1309115, 39.9828671]
  },
]

const getImagePaths = (marker) => (
  new Array(marker.numPics).fill('').map((t, i) => (
    `trip/${marker.id}/${i + 1}.jpg`
  ))
)

export default function TripMap() {
  let previousCoordinates

  const [stateName, setStateName] = useState(null)

  const onStateSelect = useCallback((stateName) => (event) => {
    setStateName(stateName)
  }, [setStateName])

  return (
    <Box mt={8}>
      <Typography variant='h4' style={{ textAlign: 'center' }}>
        Honeymoon Route
      </Typography>
      <Box mt={4}>
        <Typography>
          <em>
            Hover or click on a state to see the stops in the state.
          </em>
        </Typography>
      </Box>
      <Box style={{ cursor: 'move' }} data-tip="">
        <ComposableMap projection="geoAlbers">
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map(geo => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#DDD"
                    stroke="#FFF"
                    onMouseOver={onStateSelect(geo.properties.name)}
                    onClick={onStateSelect(geo.properties.name)}
                    style={{
                      default: {
                        fill: "#D6D6DA",
                        outline: "none"
                      },
                      hover: {
                        fill: "#F53",
                        outline: "none"
                      },
                      pressed: {
                        fill: "#E42",
                        outline: "none"
                      }
                    }}
                  />
                ))
              }
            </Geographies>
            {markers.map(({ name, coordinates, xOffset, yOffset, hideMarker }, index) => {
              const render = (
                <Fragment key={`${name}-${index}`}>
                  {!hideMarker && (
                    <Marker coordinates={coordinates}>
                      <g
                        fill="none"
                        stroke="#FF8866"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle r={4} fill="#FF8866" />
                      </g>
                    </Marker>
                  )}
                  {previousCoordinates && (
                    <Line
                      from={previousCoordinates}
                      to={coordinates}
                      stroke="#FF886666"
                      strokeWidth={2}
                      strokeDasharray='5 10'
                      strokeLinecap="round" />
                  )}
                </Fragment>
              )
              previousCoordinates = coordinates
              return render
            })}
        </ComposableMap>
      </Box>
      {
        markers.find(it => it.stateName === stateName) && (
          <ReactTooltip>
            <Box style={{ fontSize: 18 }}>
              {stateName}
              {markers.filter(it => it.stateName === stateName).map(it => (
                <p style={{ fontSize: 15 }}>
                  {it.name}: {it.date}
                </p>
              ))}
            </Box>
          </ReactTooltip>
        )
      }
      <Typography variant='h4'>
        Destinations
      </Typography>
      <List>
        {
          markers.filter(it => it.date).map((marker, index) => (
            <React.Fragment key={index}>
              <ListItem>
                  <ListItemText primary={<span><strong>{marker.name}</strong> - {marker.stateName}</span>} secondary={marker.date} />
              </ListItem>
              {marker.numPics && (
                <ListItem>
                    <PictureGrid imagePaths={getImagePaths(marker)} />
                </ListItem>
              )}
            </React.Fragment>
          ))
        }
      </List>
    </Box>
  )
}
