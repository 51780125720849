import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, AppBar, Toolbar, Button, useTheme, useMediaQuery } from '@material-ui/core'
import PictureGrid from './PictureGrid';
import TripMap from './TripMap';

const useCustomStyles = makeStyles(theme => ({
  header: {
    position: 'relative',
    backgroundImage: `url('/images/header.jpg')`,
    backgroundSize: 'cover',
    backgroundPositionY: 'center',
    width: '100vw',
    height: '40vw',
    minHeight: 300,
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1,
    color: theme.palette.background.default,
    backgroundColor: theme.palette.background.default,
    textShadow: '0 0 10px rgba(0,0,0,.8)',

    [theme.breakpoints.down('sm')]: {
      minHeight: '40vh',
      height: 'auto',
    },

    '&:before': {
      content: "''",
      position: 'absolute',
      display: 'block',
      height: '100%',
      width: '100%',
      zIndex: 1,
      background: '#6D313630'
    }
  },
  appbar: {
    zIndex: 5,
    backgroundColor: theme.palette.background.default,
    color: 'black',
  },
  title: {
    textShadow: 'none',
    fontSize: '26px',
    fontWeight: 400,
  },
  toolbar: {
    letterSpacing: 1.5,
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerButton: {
    fontWeight: 'bold',
    letterSpacing: 2.25,
    textShadow: 'none',
  },
  heading: {
    textAlign: 'center',
    margin: theme.spacing(12, 8),
    zIndex: 2,
    fontSize: 48,
  },
  story: {
    borderTop: `1px solid`,
    borderBottom: '1px solid',
    borderColor: theme.palette.text.primary,
    padding: theme.spacing(5, 0),

    '& h5': {
      textIndent: theme.spacing(4),
    }
  },
}))

const engagementPaths = [
  '1.jpg', '3.jpg', '2.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg'
]

export default function App() {
  const classes = useCustomStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box mb={20}>
      <header className={classes.header}>
        <AppBar className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title}>
              {isMobile ? 'E&K' : 'EMILY & KEVIN'}
            </Typography>
            <Box>
              <Button className={classes.headerButton} href='https://www.myregistry.com/wedding-registry/Emily-Yocum-and-Kevin-Holland-Hilliard-OH/2210195' target='_blank' rel="noopener noreferrer">
                REGISTRY
              </Button>
              <Button className={classes.headerButton} href='#honeymoon'>
                HONEYMOON
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Typography className={classes.heading}>
          Emily &amp; Kevin are married!
          <br/>
          07.23.2020
          </Typography>
        {/* <IconButton className={classes.downButton} size='medium'>
          <KeyboardArrowDown />
        </IconButton> */}
      </header>
      <Container maxWidth="md">
        <Box my={10} className={classes.story}>
          <Typography variant='h5' style={{ lineHeight: 1.5 }}>
            In the Fall of 2014, <strong>Kevin</strong> was in search of soccer players for his indoor team while he was in college at <em>Capital University</em> - where he met <strong>Emily</strong> and asked her to join. Since playing on the same co-ed soccer team, they have become cat and dog parents, purchased their first home, and traveled the country together. They instantly connected over a shared appreciation for nature and outdoor activities and so, Kevin <strong>proposed</strong> on a trip to the Southwest in <em>Zion National Park</em> as they hiked <em>The Narrows</em>.
          </Typography>
          <Typography variant='h5' style={{ lineHeight: 1.5, marginTop: '1em' }}>
            The state of <em>Montana</em> has been a long-time favorite place of Emily’s. She took Kevin a year after they started dating and he quickly fell in love with the Big Sky Country on a backpacking trip in <em>Glacier National Park</em>. Both knew they wanted a small intimate wedding, and a small resort just outside the park offered them that. Their ceremony will be held outside, with the tall peaks of <em>Glacier National Park</em> rising in the background. For their honeymoon, they plan to drive across the country and visit 16 national parks and 9 states, in a trip over 7,000 miles long. You can follow their travels below!
          </Typography>
        </Box>
        <PictureGrid imagePaths={engagementPaths} />
        <Typography>
          Pictures by <a href='https://www.erikaflugge.com/' target='_blank' rel="noopener noreferrer">Erika Flugge Photography.</a>
        </Typography>
        <div id='honeymoon'/>
        <TripMap />
      </Container>
    </Box>
  )
}
